<template>
    <div class="payment-page">
        <div class="card-body">
            <div class="card-body-title">Оплаты</div>
            <table class="table mb-20">
                <thead>
                <tr>
                    <th class="media-only-desktop">
                        <div class="t" @click="sortList('direction')">Направление</div>
                        <select v-model="filterParams.direction">
                            <option value="">Все</option>
                            <option>Входящий</option>
                            <option>Исходящий</option>
                        </select>
                    </th>
                    <th>
                        <div class="t" @click="sortList('payment_type')">Тип</div>
                        <select v-model="filterParams.payment_type">
                            <option value="">Все</option>
                            <option>Арбитражный сбор</option>
                            <option>Вознаграждение арбитра</option>
                            <option>Вознаграждение агента</option>
                        </select>
                    </th>
                    <th class="media-only-desktop">
                        <div class="t" @click="sortList('stage_dl')">Дедлайн стадии</div>
                        <input class="date" type="date" name="date" v-model="filterParams.stage_dl">
                    </th>
                    <th class="media-only-desktop">
                        <div class="t" @click="sortList('stage_title')">Cтадии</div>
                        <select v-model="filterParams.stage_title">
                            <option value="">Все</option>
                            <option v-for="stage in stages" :key="stage.id">{{ stage.title }}</option>
                        </select>
                    </th>
                    <th class="media-only-desktop">
                        <div class="t" @click="sortList('user_name')">Пользователь</div>
                        <input class="user_name" name="user_name" v-model="filterParams.user_name">
                    </th>
                    <th class="media-only-desktop">
                        <div class="t" @click="sortList('claimant_name')">Истец дела</div>
                        <input class="claimant_name" name="claimant_name" v-model="filterParams.claimant_name">
                    </th>
                    <th class="media-only-desktop">
                        <div class="t" @click="sortList('title')">Номер дела</div>
                        <input class="title" name="title" v-model="filterParams.title">
                    </th>
                    <th class="media-only-desktop">
                        <div class="t" @click="sortList('amount')">Сумма</div>
                        <input class="amount" v-model="filterParams.amount">
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in listFiltered" :key="list.key"
                    :class="{ 'tr-red' : item.direction == 'Исходящий', 'tr-green' : item.direction == 'Входящий'}">
                    <td>
                        {{ item.direction }}
                    </td>
                    <td>
                        {{ item.payment_type }}
                    </td>
                    <td>
                        {{ item.stage_dl }}
                    </td>
                    <td>
                        {{ item.stage_title }}
                    </td>
                    <td>
                        {{ item.user_name }}
                    </td>
                    <td>
                        {{ item.claimant_name }}
                    </td>
                    <td>
                        <router-link :to="`/panel/claim/${item.id}/view`">{{ item.title }}</router-link>
                    </td>
                    <td>
                        {{ item.amount }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "paymentPage",
    data() {
        return {
            claims: [],
            list: [],
            directions: [],
            types: [],
            stages: [],
            filterParams: {
                payment_type: '',
                direction: '',
                stage_dl: '',
                stage_title: '',
                user_name: '',
                claimant_name: '',
                title: '',
                amount: '',
            },
            listFiltered: [],
            sortParams: {
                field: '',
                dir: 'asc'
            }
        }
    },
    methods: {
        getClaims() {
            this.$api.get(`claim`).then(res => {
                if (res.data.success) {
                    this.list = [];
                    this.claims = res.data.data
                    this.claims.forEach(claim => {
                        let item = {
                            id: claim.id,
                            key: `${claim.id}-sbor`,
                            title: claim.title,
                            amount: claim.amount_sbor,
                            stage_dl: claim.stage_dl,
                            stage_title: claim.stage_title,
                            claimant_name: claim.claimant_name,
                            direction: claim.amount_sbor_direction === 'out' ? 'Исходящий' : 'Входящий',
                            payment_type: 'Арбитражный сбор',
                            user_name: claim.claimant_name,
                        }
                        this.list.push(item);
                        ///
                        item = {
                            id: claim.id,
                            key: `${claim.id}-arbiter`,
                            title: claim.title,
                            amount: claim.amount_arbiter,
                            stage_dl: claim.stage_dl,
                            stage_title: claim.stage_title,
                            claimant_name: claim.claimant_name,
                            direction: 'Исходящий',
                            payment_type: 'Вознаграждение арбитра',
                            user_name: claim.arbiter_name,
                        }
                        this.list.push(item);
                        //
                        if (claim.agent_id) {
                            item = {
                                id: claim.id,
                                key: `${claim.id}-agent`,
                                title: claim.title,
                                amount: claim.amount_agent,
                                stage_dl: claim.stage_dl,
                                stage_title: claim.stage_title,
                                claimant_name: claim.claimant_name,
                                direction: 'Исходящий',
                                payment_type: 'Вознаграждение агента',
                                user_name: claim.agent_name,
                            }
                        }
                        this.list.push(item);
                    })
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        filter() {
            let res = this.list;
            if (this.filterParams.direction.length) {
                res = res.filter(item => item.direction == this.filterParams.direction)
            }
            if (this.filterParams.payment_type.length) {
                res = res.filter(item => item.payment_type == this.filterParams.payment_type)
            }
            if (this.filterParams.title.length) {
                res = res.filter(item => item.title.toLowerCase().indexOf(this.filterParams.title.toLowerCase()) != -1)
            }
            if (this.filterParams.stage_dl.length) {
                res = res.filter(item => item.stage_dl.toLowerCase().indexOf(this.filterParams.stage_dl.toLowerCase()) != -1)
            }
            if (this.filterParams.stage_title.length) {
                res = res.filter(item => item.stage_title == this.filterParams.stage_title)
            }
            if (this.filterParams.user_name.length) {
                res = res.filter(item => item.user_name.toLowerCase().indexOf(this.filterParams.user_name.toLowerCase()) != -1)
            }
            if (this.filterParams.claimant_name.length) {
                res = res.filter(item => item.claimant_name.toLowerCase().indexOf(this.filterParams.claimant_name.toLowerCase()) != -1)
            }
            if (this.filterParams.amount.length) {
                res = res.filter(item => item.amount.toString().toLowerCase().indexOf(this.filterParams.amount.toLowerCase()) != -1)
            }
            this.listFiltered = res;
        },
        sortList(field) {
            if (this.sortParams.field == field) {
                this.sortParams.dir = this.sortParams.dir == 'asc' ? 'desc' : 'asc';
            } else {
                this.sortParams.field = field;
            }
            let self = this;
            switch (this.sortParams.field) {
                case 'amount':
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        return parseInt(a.amount) - parseInt(b.amount);
                    })
                    break;
                default:
                    this.listFiltered.sort(function (a, b) {
                        let c = a;
                        if (self.sortParams.dir == 'desc') {
                            a = b;
                            b = c;
                        }
                        if (a[self.sortParams.field] > b[self.sortParams.field]) {
                            return 1;
                        }
                        if (a[self.sortParams.field] < b[self.sortParams.field]) {
                            return -1;
                        }
                        return 0;
                    })
                    break;
            }
        },
        getStages() {
            this.$api.get(`claim/stages`).then(res => {
                if (res.data.success) {
                    this.stages = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
    },
    created() {
        this.getStages();
        this.getClaims();
        this.filter();
    },
    watch: {
        filterParams: {
            handler(newVal, oldVal) {
                this.filter();
            },
            deep: true,
        },
        list: function () {
            this.filter();
        }
    },
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/variables";

.table {
    tr {
        &:nth-child(2n) {
            background: #fff;
        }

        &.tr-red {
            background: lighten($cl-red, 45);
            &:hover {
                background: lighten($cl-red, 40);
            }
        }

        &.tr-green {
            background: lighten($cl-green, 60);
            &:hover {
                background: lighten($cl-green, 52);
            }
        }

        th {
            .t {
                margin: 0 0 10px;
                cursor: pointer;
            }
        }
    }
}
</style>